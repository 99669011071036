import React from "react";
import { logoutUser } from '../services/Auth';
   
   export default function Logout() {
     const loginWrapper = {
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
     }
    logoutUser();
     return(
       <div style={loginWrapper}>
         <h1>Logging out</h1>
       </div>
     )
   }