export async function loginUser(credentials) {
  return fetch(process.env.REACT_APP_API_URL + '/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

export async function registerUser(user) {
  return fetch(process.env.REACT_APP_API_URL + '/api/auth/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  })
    .then(data => data.json())
}

export async function logoutUser() {
  localStorage.removeItem('token');
  window.location.href = "/";
}

export async function getUser(token) {
  return fetch(process.env.REACT_APP_API_URL + '/api/auth/me', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      "x-access-token": token
    }
  })
    .then(data => data.json())
}

export async function updateUser(token, user) {
  return fetch(process.env.REACT_APP_API_URL + '/api/auth/me', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      "x-access-token": token
    },
    body: JSON.stringify(user)
  })
    .then(data => data.json())
}

export async function deleteUser(token) {
  return fetch(process.env.REACT_APP_API_URL + '/api/auth/me', {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      "x-access-token": token
    }
  })
    .then(data => {
      data.json()
      localStorage.removeItem('token');
      window.location.href = "/";
    })
}

export async function linkFamily(token, linkCode) {
  return fetch(process.env.REACT_APP_API_URL + '/api/auth/link/' + linkCode, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      "x-access-token": token
    },
  })
    .then(data => data.json())
}

export async function unlinkFamily(token) {
  return fetch(process.env.REACT_APP_API_URL + '/api/auth/link', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      "x-access-token": token
    },
  })
    .then(data => data.json())
}