import React, { useState } from "react";
import { loginUser } from '../services/Auth';
import { Link as RouterLink } from "react-router-dom";
import { Container, Typography, Box, TextField, Button } from '@mui/material';

export default function Login({ setToken }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      email: email,
      password: password
    });
    setToken(token);
  }


  return (
    <div>
      <Container maxWidth="xs">

        <Box align="center">
          <Typography variant="h4" gutterBottom>
            Please Log In
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField required fullWidth margin="normal" label="Login" variant="standard" onChange={e => setEmail(e.target.value)} />
          <TextField required fullWidth margin="normal" type="password" label="Password" variant="standard" onChange={e => setPassword(e.target.value)} />
          <Box justifyContent="space-between" display="flex" mt={2}>
            <Button variant="contained" type="submit">Login</Button>
            <Button component={RouterLink} variant="text" to="/register">Sign up</Button>
          </Box>
        </form>
      </Container>
    </div>
  )
}