// import "./Profile.css";
import { getUser, updateUser, unlinkFamily, deleteUser } from '../services/Auth';
import React, { useEffect, useState } from "react"
import useToken from './useToken';
import { Link as RouterLink } from "react-router-dom";
import { Container, Typography, Box, TextField, Button, Stack } from '@mui/material';

const Profile = props => {
  const { token } = useToken();
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [linkCode, setLinkCode] = useState("");
  const [familyId, setFamilyId] = useState("");

  const handleSubmit = async e => {
    e.preventDefault();
    await updateUser(token, {
      name: name,
      email: username,
      linkCode: linkCode
    });
  }

  useEffect(() => {
    getUser(token)
      .then(user => {
        setName(user.name);
        setUserName(user.email);
        setLinkCode(user.linkCode);
        setFamilyId(user.familyId);
      });

  }, [token])

  useEffect(() => {
    if (linkCode === "NEW") {
      setLinkCode(Math.random().toString(36).slice(7).toUpperCase())
    }
  }, [linkCode])

  const deleteFamily = async e => {
    await unlinkFamily(token);
  }

  const deleteAccount = async e => {
    await deleteUser(token);
  }

  return (
    <>
      <Container maxWidth="sm">
        <Box align="center">
          <Typography variant="h4" gutterBottom>
            Profile
          </Typography>
        </Box>
        <Box>
          <form onSubmit={handleSubmit}>
            <TextField required fullWidth margin="normal" label="Name" value={name} onChange={e => setName(e.target.value)} />
            <TextField required fullWidth margin="normal" label="Email" value={username} onChange={e => setUserName(e.target.value)} />
            <TextField fullWidth margin="normal" type="password" label="Password" value={password} onChange={e => setPassword(e.target.value)} />
            <TextField fullWidth margin="normal" label="Link Code" value={linkCode} onChange={e => setLinkCode(e.target.value)} />
            <TextField disabled fullWidth margin="normal" label="Family ID" value={familyId} />
            <Stack>
              <Button disabled={!familyId} variant="text" onClick={deleteFamily}>Unlink Family</Button>
              <Button variant="text" color="error" onClick={deleteAccount}>Delete user</Button>
            </Stack>
            <Box justifyContent="space-between" display="flex" mt={2}>
              <Button variant="contained" type="submit">Submit</Button>
              <Button variant="text" component={RouterLink} to="/">Cancel</Button>
            </Box>
          </form>
        </Box>
      </Container>

    </>
  )
}
export default Profile