export async function getCards(token) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("x-access-token", token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return await fetch(process.env.REACT_APP_API_URL + "/api/cards", requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            if (data === null) {
                return null;
            }
            if (data.message === "Failed to authenticate token.") {
                throw new Error("Your login has expired. Please re-enter your details.");
            }
            return data;
        }

        )
        .catch(error => console.log(error));

}

export async function saveCards(token, cards) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", token);

    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(cards)
    };

    return await fetch(process.env.REACT_APP_API_URL + "/api/cards", requestOptions)
        .then(function () {
            console.log("Cards updated.");
        })
        .catch(error => console.log('error', error));
}