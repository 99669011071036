import React, { useState, useEffect } from 'react'
import { getUser, updateUser, linkFamily } from '../services/Auth';
import useToken from './useToken';

const FamilyLink = family => {
    const { token } = useToken();
    const [linkCode, setLinkCode] = useState();

    const link = () => {
        linkFamily(token, linkCode);
        window.location.reload();
    }

    useEffect(() => {
        getUser(token)
        .then(user => {
            if (user.linkCode) {
                setLinkCode(user.linkCode);
            } else {
                //generate a random 6 digit (slice 7) alpha code
                let newCode = Math.random().toString(36).slice(7).toUpperCase();
                updateUser(token, {...user, linkCode: newCode})
                setLinkCode(newCode);
            }
        });

}, [token])    

    return (
        <>
            <div>
                Ready to start? Send your partner your link code ({linkCode}) or enter theirs here.
            </div>
            <label>
                Family code
                <input type="text" onChange={e => setLinkCode(e.target.value)} />
            </label>
            <button onClick={link}>Link</button>
        </>
    )
}
export default FamilyLink