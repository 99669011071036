import CardGrid from './components/CardGrid'
import Login from './components/Login';
import Register from './components/Register';
import Logout from './components/Logout';
import Profile from './components/Profile';
import useToken from './components/useToken';
import { Routes, Route } from "react-router-dom";

function App() {

  const { token, setToken } = useToken();

  if (!token) {
    return (
      <Routes>
        <Route path="/" element={<Login setToken={setToken} />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<CardGrid />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    );
  }

}

export default App;
