import React, { useState } from 'react'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import { saveCards } from '../services/Cards'
import { Box, Typography, Button, IconButton, FormGroup, FormControlLabel, TextField, Checkbox, Select, MenuItem, Stack, FormControl } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Menu } from '@mui/material';

const Card = props => {
    const { setIsCardOpen } = props;
    const [card, setCard] = useState(props.selCard)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [anchorElActionMenu, setAnchorElActionMenu] = useState(null);
    const openActionMenu = Boolean(anchorElActionMenu);
    const handleClickActionMenu = (event) => {
        setAnchorElActionMenu(event.currentTarget);
    };
    const handleCloseActionMenu = () => {
        setAnchorElActionMenu(null);
    };

    const handleClose = () => {
        setIsCardOpen(false);
    };

    useKeyboardShortcut(
        ["Meta", "Enter"],
        shortcutKeys => !card._id ? addNewCard() : updateCard(),
        {
            overrideSystem: false,
            ignoreInputFields: false,
            repeatOnHold: false
        }
    );

    useKeyboardShortcut(
        ["ArrowLeft"],
        shortcutKeys => (document.activeElement.tagName === 'BODY' || document.activeElement.classList.contains("modal")) && prevCard()
    );

    useKeyboardShortcut(
        ["ArrowRight"],
        shortcutKeys => (document.activeElement.tagName === 'BODY' || document.activeElement.classList.contains("modal")) && nextCard()
    );

    useKeyboardShortcut(
        ["Escape"],
        shortcutKeys => setIsCardOpen(false)
    );

    const prevCard = () => {
        if (!props.selCard["_id"])
            return;
        let selCardIndex = 0;
        let selCardDeck = "";

        ["left", "right", "unassigned", "suppressed"].forEach(deck => {
            let index = props.items[deck].findIndex(item => item._id === card._id);
            if (index > -1) {
                selCardIndex = index;
                selCardDeck = deck;
            }

        })
        if (selCardIndex === 0) {
            setCard(props.items[selCardDeck][selCardIndex]);
        } else {
            setCard(props.items[selCardDeck][selCardIndex - 1]);
        }
        setAnchorElActionMenu(null)
    }
    const nextCard = () => {
        if (!props.selCard["_id"])
            return;
        let selCardIndex = 0;
        let selCardDeck = "";

        ["left", "right", "unassigned", "suppressed"].forEach(deck => {
            let index = props.items[deck].findIndex(item => item._id === card._id);
            if (index > -1) {
                selCardIndex = index;
                selCardDeck = deck;
            }

        })
        if (selCardIndex === props.items[selCardDeck].length - 1) {
            setCard(props.items[selCardDeck][selCardIndex]);
        } else {
            setCard(props.items[selCardDeck][selCardIndex + 1]);
        }
        setAnchorElActionMenu(null)
    }

    const handleDeal = (who) => {

        var updatedLeft = (props.items.left.filter(item => item._id !== card._id))
        var updatedRight = (props.items.right.filter(item => item._id !== card._id))
        var updatedUnassigned = (props.items.unassigned.filter(item => item._id !== card._id))
        var updatedSuppressed = (props.items.suppressed.filter(item => item._id !== card._id))

        switch (who) {
            case "left":
                updatedLeft.push(props.selCard);
                break;
            case "right":
                updatedRight.push(props.selCard);
                break;
            case "unassigned":
                updatedUnassigned.push(props.selCard);
                break;
            case "suppressed":
                updatedSuppressed.push(props.selCard);
                break;
            default:
                break;
        }

        props.setItems({ left: updatedLeft, right: updatedRight, unassigned: updatedUnassigned, suppressed: updatedSuppressed })
        setCard({});
        setAnchorElActionMenu(null)
        props.setIsCardOpen(false);
    }

    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setCard({ ...card, [e.target.name]: value })
    }

    const updateCard = () => {
        if (card._id) {
            props.setItems(prevState => {
                let updatedLeft = prevState.left.map(item => {
                    if (item._id === card._id) {
                        return card;
                    }
                    return item;
                });
                let updatedRight = prevState.right.map(item => {
                    if (item._id === card._id) {
                        return card;
                    }
                    return item;
                });
                let updatedUnassigned = prevState.unassigned.map(item => {
                    if (item._id === card._id) {
                        return card;
                    }
                    return item;
                });
                let updatedSuppressed = prevState.suppressed.map(item => {
                    if (item._id === card._id) {
                        return card;
                    }
                    return item;
                });
                return { left: updatedLeft, right: updatedRight, unassigned: updatedUnassigned, suppressed: updatedSuppressed };
            })
            setCard({});
            props.setIsCardOpen(false);
        }
    }

    async function addNewCard() {

        let updatedCards = {
            left: props.items.left,
            right: props.items.right,
            unassigned: [...props.items.unassigned, { ...props.selCard }],
            suppressed: props.items.suppressed
        }
        saveCards(props.token, updatedCards)
            .then((data) => {
                window.location.reload();
            })
    }

    const deleteCustomCard = () => {


        var updatedLeft = (props.items.left.filter(item => item._id !== card._id))
        var updatedRight = (props.items.right.filter(item => item._id !== card._id))
        var updatedUnassigned = (props.items.unassigned.filter(item => item._id !== card._id))
        var updatedSuppressed = (props.items.suppressed.filter(item => item._id !== card._id))

        props.setItems({ left: updatedLeft, right: updatedRight, unassigned: updatedUnassigned, suppressed: updatedSuppressed })
        setCard({});
        setAnchorElActionMenu(null)
        props.setIsCardOpen(false)
    }

    return (
        <>
            <Dialog open={props.isCardOpen} onClose={handleClose} fullScreen={fullScreen}>
                <DialogTitle>
                    <Stack direction="columns" justifyContent="space-between">
                        <Box width="80%">
                            <TextField
                                InputProps={{ style: { fontSize: 30, fontWeight: 600 } }}
                                variant="standard"
                                fullWidth
                                name="name"
                                value={card.name}
                                onChange={handleChange}
                            />
                        </Box>
                        <IconButton
                            id="action-button"
                            color="primary"
                            size="small"
                            aria-controls={openActionMenu ? 'action-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openActionMenu ? 'true' : undefined}
                            onClick={handleClickActionMenu}
                        >
                            <EditIcon />
                            Actions
                        </IconButton>
                        <Menu
                            id="action-menu"
                            anchorEl={anchorElActionMenu}
                            open={openActionMenu}
                            onClose={handleCloseActionMenu}
                            MenuListProps={{
                                'aria-labelledby': 'action-button',
                            }}
                        >
                            <MenuItem
                                onClick={e => { handleDeal("left") }}
                            >Deal to {props.leftUserName}</MenuItem>
                            <MenuItem
                                onClick={e => { handleDeal("right") }}
                            >Deal to {props.rightUserName}</MenuItem>
                            <MenuItem
                                onClick={e => { handleDeal("unassigned") }}
                            >Unassign</MenuItem>
                            <MenuItem
                                value="Suppress"
                                onClick={e => { handleDeal("suppressed") }}
                            >Suppress</MenuItem>
                            <MenuItem
                                color="error"
                                onClick={e => { deleteCustomCard() }}
                                disabled={card.type !== 'custom'}
                            >Delete</MenuItem>
                        </Menu>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography variant="caption">
                            {props.leftUserName}
                        </Typography>
                    </Box>

                    <form>
                        <Box mt={2} mb={2}>
                            <Stack mt={2} mb={2} spacing={2} direction="row">
                                <FormControl>
                                    {/* <InputLabel id="label-suit">Suit</InputLabel> */}
                                    <Select
                                        size="small"
                                        name="suit"
                                        id="suit"
                                        labelId="label-suit"
                                        value={card.suit}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value='grapefruit'>Grapefruit</MenuItem>
                                        <MenuItem value='diamonds'>Diamonds</MenuItem>
                                        <MenuItem value='coconut'>Coconut</MenuItem>
                                        <MenuItem value='mango'>Mango</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="grind"
                                                id="grind"
                                                checked={card.grind}
                                                onChange={handleChange}
                                            />
                                        }
                                        label="Daily Grind"
                                    />
                                </FormGroup>
                            </Stack>
                            <TextField
                                variant="standard"
                                multiline
                                fullWidth
                                label="Definition"
                                name="definition"
                                value={card.definition}
                                placeholder="Enter a definition"
                                onChange={handleChange}
                            />
                            <TextField
                                variant="standard"
                                multiline
                                fullWidth
                                label="Conception"
                                name="conception"
                                value={card.conception}
                                placeholder="Enter a steps for conceiving"
                                onChange={handleChange}
                            />
                            <TextField
                                variant="standard"
                                multiline
                                fullWidth
                                label="Planning"
                                name="planning"
                                value={card.planning}
                                placeholder="Enter steps for planning"
                                onChange={handleChange}
                            />
                            <TextField
                                variant="standard"
                                multiline
                                fullWidth
                                label="Execution"
                                name="execution"
                                value={card.execution}
                                placeholder="Enter steps for execution"
                                onChange={handleChange}
                            />
                            <TextField
                                variant="standard"
                                label="Minimum Standard of Care'"
                                multiline
                                fullWidth
                                name="msc"
                                value={card.msc}
                                placeholder="Enter mutually agreed minimmum standard for conceiving, planning & execution of task"
                                onChange={handleChange}
                            />
                        </Box>
                    </form>
                </DialogContent>
                <DialogActions style={{ justifyContent: "space-between" }}>
                    {card._id &&
                        <>
                            <Box mt={2}>
                                <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={e => { prevCard() }}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                                <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={e => { nextCard() }}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Box>
                            <Box display="flex" mt={2}>
                                <Button
                                    value="Cancel"
                                    onClick={() => props.setIsCardOpen(false)}
                                >Cancel</Button>
                                <Button
                                    variant="contained"
                                    value="Update"
                                    onClick={updateCard}
                                >Update</Button>
                            </Box>
                        </>
                    }

                    {!card._id &&
                        <>
                            <Button
                                value="Cancel"
                                onClick={() => props.setIsCardOpen(false)}
                            >Cancel</Button>
                            <Button
                                value="Save"
                                onClick={addNewCard}
                            >Save</Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Card