import "./CardGrid.css";
import React, { useEffect, useState } from "react"
import Header from './Header'
import Card from './Card'
import FamilyLink from './FamilyLink'
import useToken from './useToken';
import { getUser } from '../services/Auth';
import { getCards, saveCards } from '../services/Cards';
import { getFamily } from '../services/Family';
import { SampleCardsData } from '../MockData';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import {
    GridContextProvider,
    GridDropZone,
    GridItem,
    swap,
    move
} from "react-grid-dnd";

const CardGrid = () => {
    const { token } = useToken();
    const [loginName, setLoginName] = useState();
    const [isCardOpen, setIsCardOpen] = useState(false);
    const [selItem, setSelItem] = useState({});
    const [items, setItems] = useState({ left: [], right: [], unassigned: [], suppressed: [] });
    const [family, setFamily] = useState({ leftUserId: {}, rightUserId: {} });
    const [unassignedCount, setUnassignedCount] = useState(0);
    const [suppressedCount, setSuppressedCount] = useState(0);
    const [leftUserName, setLeftUserName] = useState("");
    const [rightUserName, setRightUserName] = useState("");


    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        const init = async () => {

            const user = await getUser(token)
            if (!user) {
                return
            }
            setLoginName(user.name);

            const family = await getFamily(token)

            if (!family) {
                return
            }
            if (!family.leftUserId || !family.leftUserId.name) {
                family.leftUserId = {};
                family.leftUserId.name = "";
            }
            if (!family.rightUserId || !family.rightUserId.name) {
                family.rightUserId = {};
                family.rightUserId.name = "";
            }
            setFamily(family);
            setLeftUserName(family.leftUserId.name);
            setRightUserName(family.rightUserId.name);

            const cards = await getCards(token)

            if (cards === null) {
                console.log("User has no cards, using sample data")
                setItems({ left: [], right: [], unassigned: [...SampleCardsData.left, ...SampleCardsData.right], suppressed: [] })
            } else {
                setItems({ left: cards.left, right: cards.right, unassigned: cards.unassigned, suppressed: cards.suppressed });
            }
        };

        init();

    }, [token])

    useEffect(() => {
        if (items && items.suppressed)
            setSuppressedCount(items.suppressed.length);

        if (items && items.unassigned)
            setUnassignedCount(items.unassigned.length);
    }, [items])

    useEffect(() => {
        if (items && Object.entries(items.left).length + Object.entries(items.right).length + Object.entries(items.unassigned).length + Object.entries(items.suppressed).length !== 0) {
            if (family['name']) {
                const timer = setTimeout(() => {
                    saveCards(token, items)
                }, 500);
                return () => clearTimeout(timer);
            } else {
                console.log("No family set up, so not saving", family)
            }
        }
    }, [items, family, token])

    const openCards = (deck) => {
        if (items[deck].length > 0) {
            setSelItem(items[deck][0]);
            setIsCardOpen(true);
        }
    }

    const newCard = () => {
        setSelItem({
            name: "New custom card",
            suit: "diamonds",
            type: "custom"
        });
        setIsCardOpen(true);
    }


    const handleClick = (event, card) => {
        switch (event.detail) {
            case 1: {
                break;
            }
            case 2: {
                setSelItem(card);
                setIsCardOpen(true);
                break;
            }
            case 3: {
                break;
            }
            default: {
                break;
            }
        }
    }

    function onChange(sourceId, sourceIndex, targetIndex, targetId) {
        if (targetId) {
            const result = move(
                items[sourceId],
                items[targetId],
                sourceIndex,
                targetIndex
            );
            return setItems({
                ...items,
                [sourceId]: result[0],
                [targetId]: result[1]
            });
        }

        const result = swap(items[sourceId], sourceIndex, targetIndex);
        return setItems({
            ...items,
            [sourceId]: result
        });
    }

    return (
        <>
            <Header loginName={loginName} />
            <Box mt={1}>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button
                        value="New"
                        onClick={newCard}
                    >New card</Button>
                    <Button
                        value="Assgin"
                        onClick={e => { openCards("unassigned") }}
                    >Unassigned cards ({unassignedCount})</Button>
                    <Button
                        value="Suppressed"
                        onClick={e => { openCards("suppressed") }}
                    >Suppressed cards ({suppressedCount})</Button>
                </ButtonGroup>
            </Box>
            {(!family['leftUserId']['name'] || !family['rightUserId']['name']) &&
                <FamilyLink family={family} />
            }

            {items && items.left && items.right &&

                <>
                    <GridContextProvider onChange={onChange}>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} maxWidth="800px" alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item xs={4}>
                                    <Item>
                                    {leftUserName}
                                        <GridDropZone
                                            className="dropzone left"
                                            id="left"
                                            boxesPerRow={5}
                                            rowHeight={100}
                                        >
                                            {items.left.map((item, index) => (
                                                <GridItem key={index} onClick={e => { handleClick(e, item) }}>
                                                    <div className="grid-item">
                                                        <div className="grid-item-content">
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                </GridItem>
                                            ))}
                                        </GridDropZone>
                                    </Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item>
                                    {rightUserName}
                                        <GridDropZone
                                            className="dropzone right"
                                            id="right"
                                            boxesPerRow={5}
                                            rowHeight={100}
                                        >
                                            {items.right.map((item, index) => (
                                                <GridItem key={index} onClick={e => { handleClick(e, item) }}>
                                                    <div className="grid-item">
                                                        <div className="grid-item-content">
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                </GridItem>
                                            ))}
                                        </GridDropZone>
                                    </Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item>
                                    Unassigned
                                        <GridDropZone
                                            className="dropzone left"
                                            id="unassigned"
                                            boxesPerRow={5}
                                            rowHeight={100}
                                        >
                                            {items.unassigned.map((item, index) => (
                                                <GridItem key={index} onClick={e => { handleClick(e, item) }}>
                                                    <div className="grid-item">
                                                        <div className="grid-item-content">
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                </GridItem>
                                            ))}
                                        </GridDropZone>
                                    </Item>
                                </Grid>                                
                            </Grid>
                        </Box>
                    </GridContextProvider>
                </>
            }
            {isCardOpen &&
                <Card
                    selCard={selItem}
                    setSelCard={setSelItem}
                    leftUserName={leftUserName}
                    rightUserName={rightUserName}
                    items={items}
                    setItems={setItems}
                    isCardOpen={isCardOpen}
                    setIsCardOpen={setIsCardOpen}
                    move={move}
                    token={token}
                />
            }
        </>
    );
}


export default CardGrid