import React, { useState } from "react";
import { registerUser } from '../services/Auth';
import { Link as RouterLink } from "react-router-dom";
import { Container, Typography, Box, TextField, Button } from '@mui/material';

export default function Register() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    await registerUser({
      name: name,
      email: email,
      password: password
    });
  }

  return (
    <Container maxWidth="xs">
      <Box align="center">
        <Typography variant="h4" gutterBottom>
          Sign up
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <TextField required fullWidth margin="normal" label="Full name" variant="standard" onChange={e => setName(e.target.value)} />
        <TextField required fullWidth margin="normal" label="Email" variant="standard" onChange={e => setEmail(e.target.value)} />
        <TextField required fullWidth margin="normal" type="password" label="Password" variant="standard" onChange={e => setPassword(e.target.value)} />
        <Box justifyContent="space-between" display="flex" mt={2}>
          <Button variant="contained" type="submit">Submit</Button>
          <Button variant="text" component={RouterLink} to="/">Cancel</Button>
        </Box>
      </form>
    </Container>
  )
}