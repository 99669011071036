export const FamilyData = {
    leftUserId: {
        name: "Adam"
    },
    rightUserId: {
        name: "Erin"
    }
}
export const SampleCardsData = {
    left: [
        { name: "Adult Friendships (Player 1)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Adult Friendships (Player 2)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Aging/Ailing Parent", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Auto", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Bathing & Grooming (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Bedtime Routine (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Birth Control", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Birthday Celebrations (Other Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Birthday Celebrations (Your Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Calendar Keeper", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Cash & Bills", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Charity, Community Service & Good Deeds", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Childcare Helpers (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Civic Engagement & Cultural Enrichment", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Cleaning", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Clothes & Accessories (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Death", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Dental (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Diapering & Potty Training (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Discipline & Screen Time (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Dishes", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Dry Cleaning", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Electronics & IT", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Estate Planning & Life Insurance", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Extended Family", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Extracurricular (Kids, Non-Sports)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Extracurriculars (Kids, Sports)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "First Year of Infants Life", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "First-Aid, Safety & Emergency", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Friendships & Social Media (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Fun & Playing (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Garbage", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Gestures of Love (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Gifts (Family)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Gifts (VIP)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Glitch in the Matrix/ Daily Disruption", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Groceries", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Grooming & Wardrobe (Player 1)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Grooming & Wardrobe (Player 2)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Hard Questions (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Health Insurance", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Holiday Cards", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Holidays", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" }
    ],
    right: [
        { name: "Home Furnishings", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Home Goods and Supplies", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Home Maintenance", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Home Purchase/Rental, Mortgage & Insurance", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Home Renovation", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Homework, Projects & School Supplies (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Hosting", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Informal Education (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Job Loss & Money Problems", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Laundry", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Lawn & Plants", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Magical Beings (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Mail", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Marriage & Romance", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Meals (School Lunch)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Meals (Weekday Breakfast)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Meals (Weekday Dinner)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Meals (Weekend)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Medical & Healthy Living (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Memories & Photos", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Middle of the Night Comfort (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Money Manager", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Morning Routine (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Moving", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "New Job", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Packing & Unpacking (Kids, Local)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Packing & Unpacking (Travel)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Parents & In-Laws", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Partner Coach", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Pets", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Points, Miles & Coupons", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Returns & Store Credits", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "School Breaks (Kids, Non-Summer)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "School Breaks (Kids, Summer)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "School Forms (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "School Service (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "School Transitions", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Self-Care (Player 1)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Self-Care (Player 2)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Serious Illness", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Showing Up & Participating", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Social Plans (Couple)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Special Needs & Mental Health (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Spirituality", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Storage, Garage, & Seasonal Items", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Teacher Communication (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Thank You Notes", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Tidying up, Organizing & Donations", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Transportation (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Travel", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Tutoring & Coaching", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Unicorn Space", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Values & Good Deeds (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Watching (Kids)", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Weekend Plans", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" },
        { name: "Welcoming a Child Into The Home", userId: "", type: "card", suit: "diamonds", grind: false, definition: "", conception: "", planning: "", execution: "", msc: "" }
    ],
    unassigned: []
}